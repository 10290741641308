/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';

import {
  OptionConfiguration,
  ProductOptionItem,
  ProductOptionRangeType,
  CartItemOptionItems,
  SUPPORTED_LANGUAGES,
  StockDisplayCondition,
} from '@pedix-workspace/utils';

import { PropInSelectedLanguagePipe } from '@pedix-workspace/pedixapp-shared-pipes';
import {
  FormValidationComponent,
  InputCheckboxGroupComponent,
  InputRadioGroupComponent,
} from '@pedix-workspace/angular-ui-form-reactive';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { ProductOptionItemRadioComponent } from './product-option-item-radio/product-option-item-radio.component';
import { ProductOptionItemCheckboxComponent } from './product-option-item-checkbox/product-option-item-checkbox.component';

type ValidationErrorRule = { type: 'range'; min?: number; max?: number; message: string };

type ProductOptionSelector = Pick<
  OptionConfiguration,
  'id' | 'name' | 'rangeType' | 'min' | 'max' | 'displayItemQuantities' | 'items'
>;

@Component({
  selector: 'pxw-product-option-selector',
  templateUrl: './product-option-selector.component.html',
  styleUrls: ['./product-option-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,

    // ProductOptionItemRadioComponent,
    // ProductOptionItemCheckboxComponent,
    InputRadioGroupComponent,
    InputCheckboxGroupComponent,
    PropInSelectedLanguagePipe,
    FormValidationComponent,
    FormsModule,
    ProductOptionItemRadioComponent,
    ProductOptionItemCheckboxComponent,
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ProductOptionSelectorComponent implements OnInit {
  @Input() option: ProductOptionSelector;
  @Input() value: CartItemOptionItems;
  @Input() selectedLanguage: SUPPORTED_LANGUAGES | undefined;
  @Input() cartItemId: string | undefined;
  @Input() itemType: 'presentation' | 'option-item';
  @Input() displaySku: boolean;
  @Input() displayErrors: boolean;
  @Input({ required: true }) stockDisplayCondition: StockDisplayCondition;
  @Input({ required: true }) stockDisplayConditionValue: number;

  @Output() valueChange = new EventEmitter<CartItemOptionItems>();
  @Output() itemImageClick = new EventEmitter<ProductOptionItem>();

  singleChoiceSelection: string;
  multipleChoiceSelection?: string[];

  private t = inject(TranslocoService);

  constructor() {}

  get isSingleChoice(): boolean {
    return this.option.rangeType === ProductOptionRangeType.FIXED && this.optionMin === 1;
  }

  get optionMin() {
    if (this.itemType === 'option-item' && this.option.rangeType === ProductOptionRangeType.ANY) {
      return 0;
    }
    if (this.itemType === 'presentation') {
      return Math.max(1, this.option.min);
    }
    return this.option.min;
  }

  get optionRequired(): boolean {
    return this.optionMin > 0;
  }

  get selectedItems() {
    return this.option.items?.filter(item => item.quantity && item.quantity > 0) || [];
  }

  get selectedItemsCount(): number {
    return this.selectedItems.reduce((sum: any, item) => (sum += item.quantity || 1), 0);
  }

  get disableIncrement(): boolean {
    if (this.option.rangeType === ProductOptionRangeType.ANY) {
      return false;
    }

    const max =
      this.option.rangeType === ProductOptionRangeType.RANGE ? this.option.max : this.optionMin;

    return max ? this.selectedItemsCount >= max : false;
  }

  get disabledOptionKeys(): string[] {
    return (
      this.option.items
        ?.map(item => {
          if ((item.quantity || 0) === 0 && this.disableIncrement) {
            return item.id;
          }
          return item.stockQty === 0 ? item.id : null;
        })
        .filter((id: string | null): id is string => Boolean(id)) || []
    );
  }

  get itemsMaxCountSelection(): Record<string, number> {
    const itemsMaxCountSelection = {};

    this.option.items?.forEach(item => {
      let max: number;

      if (this.option.rangeType === ProductOptionRangeType.RANGE && this.option.max) {
        max = this.option.max;
      } else if (this.option.rangeType === ProductOptionRangeType.FIXED && this.optionMin) {
        max = this.optionMin;
      } else {
        max = Number.MAX_SAFE_INTEGER;
      }

      const count = Math.min(max, item.maxSelection || Number.MAX_SAFE_INTEGER);

      (itemsMaxCountSelection as any)[item.id] = count;
    });

    return itemsMaxCountSelection;
  }

  get optionInstructions(): string {
    if (this.option.rangeType === ProductOptionRangeType.FIXED) {
      return this.optionMin === 1
        ? ''
        : this.t.translate('product.optionInstructionFixedMany', { min: this.optionMin });
    }
    if (this.option.rangeType === ProductOptionRangeType.RANGE) {
      if (this.optionMin && this.option.max) {
        return this.t.translate('product.optionInstructionRange', {
          min: this.optionMin,
          max: this.option.max,
        });
      }
      if (this.optionMin) {
        if (this.optionMin === 1) {
          return this.t.translate('product.optionInstructionRangeFromOne');
        }
        return this.t.translate('product.optionInstructionRangeFromMany', { min: this.optionMin });
      }
      if (this.option.max) {
        if (this.option.max === 1) {
          return this.t.translate('product.optionInstructionRangeToOne');
        }
        return this.t.translate('product.optionInstructionRangeToMany', { max: this.option.max });
      }
    }
    return this.t.translate('product.optionInstructionDefault');
  }

  get validationErrorRule(): ValidationErrorRule | undefined {
    if (this.option.rangeType === ProductOptionRangeType.FIXED) {
      if (this.optionMin === 1 && this.option.max === 1) {
        return this.createValidationErrorRule({
          message: this.t.translate('product.optionInstructionFixedOne', { min: 1, max: 1 }),
          min: 1,
          max: 1,
        });
      }
      return this.createValidationErrorRule({
        message: this.t.translate('product.optionInstructionFixedMany', {
          min: this.optionMin,
          max: this.optionMin,
        }),
        min: this.optionMin,
        max: this.optionMin,
      });
    }
    if (this.option.rangeType === ProductOptionRangeType.RANGE) {
      if (this.optionMin && this.option.max) {
        return this.createValidationErrorRule({
          message: this.t.translate('product.optionInstructionRange', {
            min: this.optionMin,
            max: this.option.max,
          }),
          min: this.optionMin,
          max: this.option.max,
        });
      }
      if (this.optionMin) {
        const message =
          this.optionMin === 1
            ? this.t.translate('product.optionInstructionRangeFromOne')
            : this.t.translate('product.optionInstructionRangeFromMany', { min: this.optionMin });

        return this.createValidationErrorRule({
          message,
          min: this.optionMin,
        });
      }
      if (this.option.max) {
        const message =
          this.optionMin === 1
            ? this.t.translate('product.optionInstructionRangeToOne')
            : this.t.translate('product.optionInstructionRangeToMany', { max: this.option.max });

        return this.createValidationErrorRule({
          message,
          max: this.option.max,
        });
      }
    }
    if (this.option.rangeType === ProductOptionRangeType.ANY) {
      if (this.optionMin) {
        const message =
          this.optionMin === 1
            ? this.t.translate('product.optionInstructionRangeFromOne')
            : this.t.translate('product.optionInstructionRangeFromMany', { min: this.optionMin });

        return this.createValidationErrorRule({
          message,
          min: this.optionMin,
        });
      }
      if (this.option.max) {
        const message =
          this.optionMin === 1
            ? this.t.translate('product.optionInstructionRangeToOne')
            : this.t.translate('product.optionInstructionRangeToMany', { max: this.option.max });

        return this.createValidationErrorRule({
          message,
          max: this.option.max,
        });
      }
    }
    return undefined;
  }

  ngOnInit() {
    if (this.value) {
      if (this.isSingleChoice) {
        this.singleChoiceSelection = this.value.items[0].id;
      } else {
        this.multipleChoiceSelection = this.value.items.map(item => item.id);
        this.value.items.forEach(selectedItem => {
          const itemToUpdate = this.option.items?.find(item => item.id === selectedItem.id);

          if (itemToUpdate) {
            itemToUpdate.quantity = selectedItem.quantity;
          }
        });
      }
    } else {
      if (this.isSingleChoice) {
        const item = this.getFirstNonDisabledItem(this.option);

        if (item) {
          this.singleChoiceSelection = item.id;
          this.emitNewSelection([item]);
        }
      } else {
        this.emitNewSelection([]);
      }
    }
  }

  getFirstNonDisabledItem(option: OptionConfiguration) {
    return option.items?.find(item => item.stockQty !== 0);
  }

  createValidationErrorRule(options: {
    message: string;
    min?: number;
    max?: number;
  }): ValidationErrorRule {
    return {
      type: 'range',
      min: options.min,
      max: options.max,
      message: options.message,
    };
  }

  onRadioItemRendered({ element }: any) {
    for (const child of element.querySelectorAll('.without-stock')) {
      (child as HTMLElement).parentElement?.classList.add('without-stock');
    }
  }

  emitNewSelection(items: ProductOptionItem[]) {
    setTimeout(() => {
      items.sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0));

      if (items.length > 0) {
        this.valueChange.emit({
          option: { ...this.option, items: [] },
          items,
        });
        this.multipleChoiceSelection = items.map(item => item.id);
      } else {
        this.valueChange.emit(undefined);
        this.multipleChoiceSelection = undefined;
      }
    }, 0);
  }

  onSingleChoiceSelected(itemId: string) {
    this.singleChoiceSelection = itemId;

    const selectedItem = this.option.items?.find(
      (item: { id: string }) => item.id === this.singleChoiceSelection,
    );

    if (selectedItem) {
      this.emitNewSelection([selectedItem]);
    } else {
      this.emitNewSelection([]);
    }
  }

  onToggleOption(optionItem: ProductOptionItem, nextState: boolean, count?: number) {
    if (nextState === false) {
      delete optionItem.quantity;
    } else {
      optionItem.quantity = count || 1;
    }

    this.emitNewSelection(this.selectedItems);
  }

  onOptionItemCountChange(item: ProductOptionItem, count: number) {
    if (count > 0) {
      this.onToggleOption(item, true, count);
    } else {
      this.onToggleOption(item, false);
    }

    this.emitNewSelection(this.selectedItems);
  }

  onItemImageClick(item: ProductOptionItem) {
    this.itemImageClick.emit(item);
  }
}
